import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from './authorization.service';
import {Role} from '../../../user/shared/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ValidatorGuardService implements CanActivate {

  constructor(private router: Router,
              private authorizationService: AuthorizationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authorizationService.hasRole(Role.CUSTOMER_VALIDATOR);
  }
}
