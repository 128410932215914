import {WorkFunction} from './work-function.model';
import {Serializer} from '../serializer.interface';

export class WorkFunctionSerializer implements Serializer {

  fromJson(json: any): WorkFunction {
    const workFunction: WorkFunction = new WorkFunction();
    workFunction.id = json.id;
    workFunction.category = json.category;
    workFunction.code = json.code;
    workFunction.nameDE = json.nameDE;
    workFunction.nameEN = json.nameEN;
    workFunction.nameFR = json.nameFR;
    workFunction.nameNL = json.nameNL;

    return workFunction;
  }

  toJson(workFunction: WorkFunction): any {
    return {};
  }
}
