<div class="container-fluid shadowbox">

  <div class="grid">

    <div class="col">

      <div class="row">

        <div class="col">

          <h5 class="heading my-3"><i class="fas fa-file-alt"></i>&nbsp;&nbsp;{{ 'planning.title' | translate }}</h5>

          <div class="text-right pr-1">

            <button mat-flat-button type="button"
                    class="ml-2" (click)="requestDialog()">
              <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;{{ 'planning.createRequest' | translate }}
            </button>

          </div>

          <div class="calendar-options-buttons">
            <button *ngIf="showAll" (click)="showAllTabs()" mat-flat-button
                    type="button"> {{'planning.closeAllTabs' | translate }}
            </button>
            <button *ngIf="!showAll" (click)="showAllTabs()" mat-flat-button
            type="button"> {{'planning.openAllTabs' | translate }}
            </button>
            
        </div>

      </div>

      <div class="row">

        <div class="col d-none d-md-block">

          <div class='main-app-calendar'>

            <div class='app-calendar'>
              <full-calendar
                #calendar
                [options]='calendarOptions'
              ></full-calendar>
            </div>

          </div>

        </div>

        <!-- <div class="col d-block d-md-none">

          <div class='main-app-calendar'>

            <div class='app-calendar'>
              <full-calendar
                #calendar
                *ngIf='calendarVisible'
                [options]='mobileCalendarOptions'
              ></full-calendar>
            </div>

          </div>

        </div> -->

      </div>

    </div>

  </div>

</div>
