import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CustomerSerializer} from '../model/customer.serializer';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Customer} from '../model/customer.model';
import {map} from 'rxjs/operators';
import {CountrySerializer} from '../../../shared/model/country/country.serializer';
import {WorkFunctionSerializer} from '../../../shared/model/work-function/work-function.serializer';
import {PrestationCodeSerializer} from '../../../shared/model/prestation-code/prestation-code.serializer';
import {WorkFunction} from '../../../shared/model/work-function/work-function.model';
import {PrestationCode} from '../../../shared/model/prestation-code/prestation-code.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private serviceURL = environment.customer_service_URL + 'customers';
  private customerSerializer: CustomerSerializer = new CustomerSerializer();
  private countrySerializer: CountrySerializer = new CountrySerializer();
  private workFunctionSerializer: WorkFunctionSerializer = new WorkFunctionSerializer();
  private prestationCodeSerializer: PrestationCodeSerializer = new PrestationCodeSerializer();

  constructor(private http: HttpClient) {
  }

  getCustomer(): Observable<any> {
    return this.http.get<Customer>(`${this.serviceURL}/app/customer/profile`).pipe(
      map((data) => this.customerSerializer.fromJson(data))
    );
  }



  // ------------------- Reference data -------------------

  listWorkFunctions(): Observable<any> {
    return this.http.get<WorkFunction[]>(this.serviceURL + '/app/customer/data/work-functions').pipe(
      map((data) => data.map(workFunction => this.workFunctionSerializer.fromJson(workFunction)))
    );
  }

  listPrestationCodeJustifications(): Observable<any> {
    return this.http.get<PrestationCode[]>(this.serviceURL + '/app/customer/data/justification-codes').pipe(
      map((data) => data.map(prestationCodes => this.prestationCodeSerializer.fromJson(prestationCodes)))
    );
  }
}
