<div *ngIf="isLoggedIn" class="wrapper">

  <app-spinner></app-spinner>

    <!-- Side Menu -->
    <nav id="sidebar">

      <!-- Space Header -->
      <div class="sidebar-header text-center">

        <!-- Brand Logo -->
        <a routerLink="/profile">
          <img class="brandlogo" src="assets/img/HS_Horeca_2022_RVB_72dpi.png" alt="Human Support"/>
        </a>

      </div>

      <ul class="list-unstyled components">

        <!-- Logged Agency -->
        <h4 class="text-center">{{currentUser?.operatingUnitName}}</h4>

        <!-- Menu -->
        <li routerLinkActive="active">
          <a routerLink="/profile">&nbsp;<i class="fas fa-desktop"></i>&nbsp;&nbsp;{{ 'menu.profile' | translate }}
          </a>
        </li>
        <li routerLinkActive="active">
          <a routerLink="/planning">&nbsp;<i
            class="far fa-calendar-alt"></i>&nbsp;&nbsp;{{ 'menu.planning' | translate }}
          </a>
        </li>
        <li routerLinkActive="active" *ngIf="hasRole()">
          <a routerLink="/prestations">&nbsp;<i
            class="fas fa-calendar-check"></i>&nbsp;&nbsp;{{ 'menu.prestations' | translate }}</a>
        </li>
        <li routerLinkActive="">
          <a href="#docsSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">&nbsp;<i
            class="fas fa-folder"></i>&nbsp;&nbsp;{{ 'menu.docs.title' | translate }}</a>
          <ul class="collapse list-unstyled" id="docsSubmenu">
            <li>
              <a routerLink="/contracts">&nbsp;&nbsp;<i
                class="fas fa-file-alt"></i>&nbsp;&nbsp;{{ 'menu.docs.contracts' | translate }}</a>
            </li>
            <li>
              <a routerLink="/invoices">&nbsp;&nbsp;<i
                class="fas fa-receipt"></i>&nbsp;&nbsp;{{ 'menu.docs.invoices' | translate }}</a>
            </li>
          </ul>
        </li>
      </ul>

    </nav>

    <!-- Log Off / User / Langues Menu -->
    <nav id="sidefooter">

      <div class="row">
          <div class="zone-language-div">

            <button mat-button [matMenuTriggerFor]="langMenu">{{ getLanguage() }}</button>

            <mat-menu #langMenu="matMenu">
              <button mat-menu-item (click)="useLanguage('en')"
              [ngStyle]="{'color' : this.getLanguage() === 'EN' ? '#17a2b8' : 'black'}">EN</button>
            <button mat-menu-item (click)="useLanguage('fr')"
            [ngStyle]="{'color' : this.getLanguage() === 'FR' ? '#17a2b8' : 'black' }">FR</button>
            <button mat-menu-item (click)="useLanguage('nl')"
            [ngStyle]="{'color' : this.getLanguage() === 'NL' ? '#17a2b8' : 'black' }">NL</button>
          </mat-menu>

        </div>

        <div class="zone-user" id="user-role">
          <span class="userposition">{{currentUser?.role}}</span>
        </div>
      </div>

      <div class="row">


        <!-- Log Off -->
        <div class="zone-delog">
          <a (click)="onLogout()"><img src="assets/img/icon_log@2x.png" alt="Log Off" class="iconposition"/></a>
        </div>

        <!-- Connected user user -->
        <!-- <span class="userLogIcon">
        <i class="fas fa-user"></i>
      </span> -->
        <div class="zone-user" id="user-username">
          <span class="userposition"><a routerLink="/user">{{currentUser?.username}}</a></span>

        </div>

      </div>

    </nav>

    <!-- Button Toggle Side Menu -->
    <button type="button" id="sidebarCollapse" class="overflowButton btn btn-info">
      <i id="toggleIcon" class="fas fa-chevron-circle-left"></i>
    </button>

  <!-- Page Content -->
  <div id="content">

    <router-outlet></router-outlet>

  </div>

</div>

<div *ngIf="!isLoggedIn" >
  <router-outlet></router-outlet>
</div>
