import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator} from '@angular/material/paginator';
import {Prestation} from './shared/model/prestation.model';
import {MatTableDataSource} from '@angular/material/table';
import {PrestationPeriod} from './shared/model/prestation-period.model';
import {FacadeService} from '../shared/service/facade/facade.service';
import {AuthorizationService} from '../login/shared/service/authorization.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PrestationRejectDialogComponent} from './prestation-reject-dialog/prestation-dialog.component';
import {PrestationValidationDialogComponent} from './prestation-validation-dialog/prestation-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrestationsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedPrestationColumns: string[] = ['title'];
  displayedPrestationPeriodColumns: string[];
  displayedPrestationPeriodColumnsNursing: string[] =
    ['statusColor', 'number', 'plannedDate', 'actualWorkerDate', 'actualCustomerDate', 'actions'];
  displayedPrestationPeriodColumnsHoreca: string[] =
    ['statusColor', 'number', 'plannedDate', 'actualDate', 'actions'];
  expandedElement: Prestation | null;
  prestationDataSource = new MatTableDataSource<Prestation>();
  prestationPeriodDataSource = new MatTableDataSource<PrestationPeriod>();

  public Prestation: typeof Prestation = Prestation;
  public PrestationPeriod: typeof PrestationPeriod = PrestationPeriod;

  constructor(private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.setPrestationDataSource(this.route.snapshot.data['prestations']);
  }

  setPrestationDataSource(prestations: Prestation[]) {
    this.prestationDataSource = new MatTableDataSource<Prestation>(prestations);
    this.prestationDataSource.paginator = this.paginator;
  }

  setPrestationPeriodDataSource(period: PrestationPeriod[]) {
    this.prestationPeriodDataSource = new MatTableDataSource<PrestationPeriod>(period);
  }

  asPrestation(prestation: Prestation): Prestation {
    return prestation;
  }

  asPeriod(period: PrestationPeriod): PrestationPeriod {
    return period;
  }

  rejectPeriod(period: PrestationPeriod, prestation: Prestation) {

    this.facadeService.getPrestationCodeJustification().subscribe({
      next: prestationCodes => {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          prestationPeriod: period,
          prestation: prestation,
          prestationCodes: prestationCodes
        };

        const dialogRef = this.dialog.open(PrestationRejectDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.openSnackBar('prestations.validation.periodRejected');
            this.refreshPrestationToValidate();
            this.expandedElement = null;
          }
        });
      },

      error: ignoreProperty => {
        this.openSnackBar(this.translate.instant('prestations.validation.dialog.message.error.prestationCode', 'customSnackError'));
      }
    });
  }

  validatePeriod(period: PrestationPeriod, prestation: Prestation) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      prestationPeriod: period,
      prestation: prestation,
      nursing: prestation.isNursing
    };

    const dialogRef = this.dialog.open(PrestationValidationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openSnackBar('prestations.validation.periodValidated');
        this.refreshPrestationToValidate();
        this.expandedElement = null;
      }
    });
  }

  refreshPrestationToValidate() {
    this.facadeService.getAllPrestationsToValidate().subscribe({
      next: (prestationsToValidate: Prestation[]) => {
        this.setPrestationDataSource(prestationsToValidate);
      },
      error: ignore => {
        this.openSnackBar('prestations.validation.dialog.message.error.refresh', 'customSnackError');
      }
    });
  }

  validatePrestation(prestation: Prestation) {
    this.facadeService.validatePrestation(prestation)
      .subscribe({
        next: ignore => {
          this.openSnackBar('Prestation validated !');
          this.refreshPrestationToValidate();
        },
        error: ignore => {
          this.openSnackBar('prestations.validation.dialog.message.error.validatePrestationPeriod', 'customSnackError');
        }
      });
  }

  setExpandedElement(element: Prestation) {
    if (this.expandedElement === element) {
      return this.expandedElement = null;
    }
    this.expandedElement = element;
    this.setPrestationPeriodDataSource(element.periods);
    this.expandedElement.isNursing ?
      this.displayedPrestationPeriodColumns = this.displayedPrestationPeriodColumnsNursing :
      this.displayedPrestationPeriodColumns = this.displayedPrestationPeriodColumnsHoreca;
  }

  getWeekNumber(prestation: Prestation): string {
    const d = new Date(Date.UTC(prestation.startDate.getFullYear(), prestation.startDate.getMonth(), prestation.startDate.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((( d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7).toString();
  }

  getStatus(period: PrestationPeriod) {
    return period.getStatus();
  }

  openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }
}
