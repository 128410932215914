<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="container-fluid shadowbox">

    <div class="grid">

      <div class="col">

        <div class="row">

          <div class="col-12">
            
            <!-- EDIT EXISTING REQUEST TITLE -->
            <h5 *ngIf="request?.id" class="heading my-3"><i class="fas fa-file-alt"></i>&nbsp;&nbsp;{{ 'request.titleEdit' | translate }} :
              {{ request?.id }}
            </h5>
            
            <!-- NEW REQUEST  -->
            <h5 *ngIf="!request?.id" class="heading my-3"><i class="fas fa-file-alt"></i>&nbsp;&nbsp;{{ 'request.title' | translate }}
            </h5>

            <div class="row pt-2">

              <div class="col-4">

                <div class="matFlex">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'request.costCenter' | translate }}</mat-label>
                    <mat-select formControlName="costCenter" id="costCenter">
                      <mat-option *ngFor="let costCenter of costCenterList;" [value]="costCenter">
                        {{ costCenter }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-4">

                <div class="matFlex">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'request.workFunction' | translate }}</mat-label>
                    <mat-select formControlName="workFunctionId" id="workFunctionId">
                      <mat-option *ngFor="let workFunction of workFunctionList;" [value]="workFunction?.id">
                        [{{ workFunction?.category }}&nbsp;|&nbsp;{{ workFunction?.code }} 
                        ]&nbsp;{{ workFunction?.nameEN }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-4">

                <div class="matFlex">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'request.worker' | translate }}</mat-label>
                    <mat-select formControlName="workerId" id="workerId">
                      <mat-option *ngFor="let worker of workerList;" [value]="worker?.id">
                       {{ worker?.lastName }}&nbsp;{{ worker?.firstName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="row">

          <div class="col">

            <div class='main-app-calendar'>

              <div class='app-calendar'>
                <full-calendar
                  #calendar
                  [options]='calendarOptions'></full-calendar>
              </div>

            </div>

          </div>

        </div>

        <div class="row">

          <div class="col-12">

            <div class="form-group">

              <div class="form-row pt-4">

                <div class="col-12">

                  <!-- Form - Button -->
                  <div class="form-row justify-content-between">

                    <div class="pl-1">

                      <a mat-flat-button color="accent" (click)="onBack()" type="button">
                        <i class="fas fa-arrow-alt-circle-left"></i>&nbsp;&nbsp;{{ 'request.back' | translate }}
                      </a>

                    </div>

                    <div>

                      <button *ngIf="request.isModifiable && request.id" (click)="deleteRequest(request)" mat-flat-button type="button" class="orangeButton">
                        <i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{ 'request.delete' | translate }}
                      </button>

                    </div>

                    <div class="text-right pr-1">

                      <button *ngIf="request.isModifiable" mat-flat-button color="primary" type="submit" class="ml-2">
                        <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ 'request.save' | translate }}
                      </button>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</form>
