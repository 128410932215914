import {PrestationCode} from './prestation-code.model';
import {Serializer} from '../serializer.interface';

export class PrestationCodeSerializer implements Serializer {
  fromJson(json: any): PrestationCode {
    const prestationCode: PrestationCode = new PrestationCode();

    prestationCode.code = json.code;
    prestationCode.labelFR = json.labelFR;
    prestationCode.labelNL = json.labelNL;
    prestationCode.labelEN = json.labelEN;
    prestationCode.labelDE = json.labelDE;

    return prestationCode;
  }

  toJson(prestationCode: PrestationCode): any {
    return {};
  }
}
