import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Contract} from './shared/model/contract.model';
import {FacadeService} from '../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['contractId', 'workerName', 'startDate', 'endDate', 'contractType', 'costCenter', 'file'];
  contracts = new MatTableDataSource<Contract>();

  constructor(private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getContracts();
  }

  asContract(contract: Contract): Contract {
    return contract;
  }

  private getContracts(): void {
    this.facadeService.listContracts().subscribe({
      next: (contracts: Contract[]) => {
        this.contracts = new MatTableDataSource<Contract>(contracts);
        this.contracts.paginator = this.paginator;
      },
      error: () => {
        this.openSnackBar(this.translate.instant('contracts.message.error.get'), 'customSnackError');
      }
    });
  }

  downloadDocument(contract: Contract) {
    this.facadeService.getContractOriginalFile(contract, this.translate.currentLang).subscribe({
      next: data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      error: () => {
        this.openSnackBar(this.translate.instant('contracts.message.error.pdf'), 'customSnackError');
      }
    });
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }
}
