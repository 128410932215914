export class PrestationPeriod {

  id: number;
  plannedStartDate: Date;
  plannedEndDate: Date;
  plannedPauseTime: Date;
  actualStartDate: Date;
  actualEndDate: Date;
  actualPauseTime: Date;
  actualWorkerStartDate: Date;
  actualWorkerEndDate: Date;
  actualWorkerPauseTime: Date;
  actualCustomerStartDate: Date;
  actualCustomerEndDate: Date;
  actualCustomerPauseTime: Date;
  justificationFileId: string;
  description: string;
  isProcessed: boolean;
  isDateNotMatched: boolean;
  isWorkerValidated: boolean;
  isCustomerValidated: boolean;
  isWorkerRejected: boolean;
  isCustomerRejected: boolean;
  isValidated: boolean;
  isRejected: boolean;
  isModifiable: boolean;
  isMissMatched: boolean;
  totalHoursToNumber: number;

  // POST
  customerJustificationCode: string;
  justificationFile: File;

  getStatus(): string {
    if (this.isValidated) {
      return 'VALIDATED';
    }
    if (this.isRejected) {
      return 'REJECTED';
    }
    if (this.isCustomerValidated) {
      return 'CUSTOMER_VALIDATED';
    }
    if (this.isCustomerRejected) {
      return 'CUSTOMER_REJECTED';
    }
    if (this.isMissMatched) {
      return 'DECISION_MISMATCH';
    }
    if (this.isDateNotMatched) {
      return 'DATE_MISMATCH';
    }
    return 'TO_VALIDATE';
  }
}
