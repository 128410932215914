<!-- Section Table -->
<div class="col-md-12 shadowbox pb-3">

  <div class="grid">

    <div class="col-12">

      <h5 class="heading my-3"><i class="fas fa-receipt"></i>&nbsp;&nbsp;{{ 'invoices.title' | translate }}</h5>

      <div class="table-responsive">

        <table mat-table [dataSource]="invoices" class="table">

          <!-- Id -->
          <ng-container matColumnDef="invoiceId">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice?.id }}</td>
          </ng-container>

          <!-- Due Date -->
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'invoices.table.dueDate' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{asInvoice(invoice).dueDate | date: 'dd/MM/yyyy'}}</td>
          </ng-container>

          <!-- Invoicing Date -->
          <ng-container matColumnDef="invoicingDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'invoices.table.invoicingDate' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{asInvoice(invoice).invoicingDate | date: 'dd/MM/yyyy'}}</td>
          </ng-container>

          <!-- Total -->
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>{{ 'invoices.table.total' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{asInvoice(invoice).totalVATIncluded | number: '1.2-2' }}&nbsp;€</td>
          </ng-container>

          <!-- Download -->
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>{{ 'invoices.table.download' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">
              <button mat-flat-button type="button" (click)="downloadDocument(invoice)">
                <i class="fas fa-file-pdf"></i>&nbsp;&nbsp;{{ 'invoices.PDF' | translate }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let invoice; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[5, 25, 75]" showFirstLastButtons></mat-paginator>

      </div>

    </div>

  </div>

</div>
