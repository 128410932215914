
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FacadeService} from '../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import enLocale from '@fullcalendar/core/locales/en-gb';
import {Prestation} from '../prestations/shared/model/prestation.model';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {UntypedFormControl} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CalendarOptions, DatesSetArg, EventApi, EventClickArg, EventHoveringArg} from '@fullcalendar/core';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import list from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {FullCalendarComponent} from '@fullcalendar/angular';
import { Period, Request } from '../request/shared/model/request.model';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, AfterViewInit {

  @ViewChild('calendar') calendarRef: FullCalendarComponent;

  showAll: boolean = true;

  calendarOptions: CalendarOptions = {
    plugins: [rrulePlugin,
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
      resourceTimelinePlugin,
      list],
    firstDay: 1,
    height: 'auto',
    locales: [enLocale, frLocale, nlLocale],
    timeZone: 'local',
    initialView: this.route.snapshot.queryParams['view'] ?? 'resourceTimelineWeek',
    views: {
      resourceTimelineWeek: {
        type: 'resourceTimelineWeek',
        resourcesInitiallyExpanded: this.showAll,
      },
    },
    resourceGroupLabelContent: function (args) {
      return {
        html: '<span style="cursor:pointer;border:none;" id="costCenter">'+ args.groupValue.split('_')[0] + '</span>'
      };
    },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'resourceTimelineDay resourceTimelineWeek listDay'
    },
    resourceGroupField: 'building',
    slotDuration: '12:00:00',
    resourceAreaColumns: [
      {
        headerContent: this.requestIdHeader.bind(this),
        field: 'requestId'
      },
      {
        headerContent: this.workerHeader.bind(this),
        field: 'worker',
        cellContent: function (args) {
          return {
            html:
              '<div id="text">' + args.resource.extendedProps.worker + '</div>'
          };
        },
      },
      {
        headerContent: this.totalHeader.bind(this),
        field: 'occupancy'
      },
      {
        field: 'workFunction',
        headerContent: this.workFunctionHeader.bind(this),
      },
    ],
    initialDate: this.route.snapshot.queryParams['fromDate'],
    eventColor: '#8BD1DC',
    eventTextColor: '#000',
    eventOrder: 'type',
    eventMinWidth: 0,
    locale: this.translate.currentLang,
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    resourceOrder: 'building, -requestId',
    nowIndicator: true,
    datesSet: this.handleDatesSet.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventMouseEnter: this.handleEventMouseEnter.bind(this)
  };

  startDate: Date;
  endDate: Date;
  customerIds: number[];
  operatingUnitIds: number[];
  countPeriods = 0;
  prestations: Prestation[] = [];
  selectedColor1 = '#F46250';
  selectedColor2 = 'accent';
  selectedColor3 = 'accent';
  selectedSize1 = 'medium';
  selectedSize2 = 'small';
  selectedSize3 = 'small';

  costCenters: any[] = [];

  operatingUnitFormControl = new UntypedFormControl();
  allOperatingUnitSelected = false;
  inAllOperatingUnitToggle = false;

  customerFormControl = new UntypedFormControl();
  allCustomerSelected = false;
  inAllCustomerToggle = false;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private datePipe: DatePipe,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
    this.costCenters = this.route.snapshot.data['customer'].costCenter;
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(next => {
      this.calendarRef.getApi().setOption('locale', next.lang);
    });
  }

 requestDialog(){
   return this.router.navigate(['request']);
 }

  ngAfterViewInit() {
    this.startDate = this.calendarRef.getApi().view.activeStart;
    this.endDate = this.calendarRef.getApi().view.activeEnd;
    this.addEvents();
  }

  showAllTabs(){
    this.showAll = !this.showAll;
    this.calendarRef.getApi().setOption('resourcesInitiallyExpanded', this.showAll);
  }

  sameEvent(event1: EventApi, event2: any) {
    return event1.extendedProps.idOccupancy === event2.idOccupancy &&
      event1.start.getTime() === event2.start.getTime() &&
      event1.end.getTime() === event2.end.getTime();
  }

  handleDatesSet(arg: DatesSetArg) {
    if (this.calendarRef) {
      this.startDate = arg.start;
      this.endDate = arg.end;
      this.addEvents();
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    const requestId = clickInfo.event.extendedProps.requestId;
    if (requestId) {
      window.open('/request?r=' + requestId, '_blank');
    }
  }

  handleEventMouseEnter(arg: EventHoveringArg) {
    const el = arg.el;
    const event = arg.event;
    const start = this.datePipe.transform(event.start, 'HH:mm');
    const end = this.datePipe.transform(event.end, 'HH:mm');
    let title = '';
    if (event.extendedProps && event.extendedProps.workFunctionLabel) {
      title += event.extendedProps.workFunctionLabel + ' | ';
    }
    title += start + ' - ' + end;
    el.setAttribute('title', title);
  }

  workFunctionHeader() {
    return this.translate.instant('planning.calendar.workFunction');
  }

  workerHeader() {
    return this.translate.instant('planning.calendar.worker');
  }

  totalHeader() {
    return this.translate.instant('planning.calendar.total');
  }

  requestIdHeader() {
    return this.translate.instant('planning.calendar.requestId');
  }

  costCenterEmptyLabel() {
    return this.translate.instant('customer.noCost-center');
  }

  private addEvents() {
    this.facadeService.getRequestByCustomerIdAndStartDateAndEndDate(this.startDate, this.endDate)
    .subscribe({
      next:
        (requests: Request[]) => {
          this.calendarRef.getApi().getResources().forEach(
            r => {
              r.remove();
            }
          );
          this.calendarRef.getApi().removeAllEvents();
          for (const request of requests) {
            const resourceId = `${request.id}${request.costCenter}`;
            const resource = {
              id: resourceId,
              building: request.costCenter === null ? this.costCenterEmptyLabel() : request.costCenter,
              requestId: request.id,
              worker: request.worker.fullName !== " " ? request.worker.fullName : this.translate.instant('planning.addResource.noWorker'),
              occupancy: `${request.totalHours}h${(request.totalMinutes < 10) ? '0' + request.totalMinutes : request.totalMinutes}`,
              workFunction: this.getTranslatedWorkFunction(request.workFunction),
              r: request.id,
              color: '#000',
            };

            this.calendarRef.getApi().addResource(resource);

            let title = this.getTranslatedWorkFunction(request.workFunction);
            if (this.calendarRef.getApi().view.type === 'listDay') {
              title += ' | ' + request?.worker.fullName;
            };

            for (const period of request.periods) {
              const event = {
                start: period.startDate,
                end: period.endDate,
                resourceId: resourceId,
                title: `${title}`,
                requestId: request.id,
                workFunctionLabel: this.getTranslatedWorkFunction(request.workFunction),
                color: ((!request.isModifiable) ? `${request?.getColorByStatus()}` : '#92D14F'),
                editable: true,
                type: 1,
              };
              this.calendarRef.getApi().addEvent(event);
            }
          }
        },
      error: ignoreProperty => {
        this.openSnackBar(this.translate.instant('planning.message.error.request'));
      }
    });
  }


  private static buildTitle(request: Request, period: Period): string {
    const worker = request.worker;
    const mobile = worker.mobile;
    const customerRemarks = worker.customerRemarks;

    let title = request.worker.fullName ? request.worker.fullName : '';

    title += period.description ? ` ${period.description}` : '';
    if (customerRemarks) {
      if (title.length > 0) {
        title += ' - ';
      }
      title += customerRemarks;
    }

    return title;
  }

  private openSnackBar(message: string, pC: string = 'customSnackError') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }

  private getTranslatedWorkFunction(workFunction) {
    switch (this.translate.currentLang) {
      case 'fr' : return workFunction.nameFR;
      case 'nl' : return workFunction.nameNL;
      case 'de' : return workFunction.nameDE;
      default : return workFunction.nameEN;
    }
  }
}
