import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {User} from '../../../user/shared/model/user.model';
import {FacadeService} from '../../service/facade/facade.service';

@Injectable()
export class UserByCodeResolver implements Resolve<Observable<User>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    const uuid = route.queryParams['uuid'];
    return this.facadeService.getUserByUuid(uuid).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
