<h5 mat-dialog-title><i class="fa fa-plus-circle"></i>&nbsp;{{ 'request.dialog.title' | translate }}</h5>

<form #form="ngForm" (ngSubmit)="submit(form)">

  <mat-dialog-content>

    <div class="container-fluid shadowbox pb-3">

      <div class="grid">

        <div class="col">

          <div class="row">

            <div class="col-xl-12">

              <div class="form-group">

                <div class="form-row">

                  <div class="col">

                    <div class="matFlex">

                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'request.dialog.description' | translate }}</mat-label>
                        <input [ngModel]="description" matInput name="description" id="description" type="text">
                      </mat-form-field>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="mb-1 justify-content-between">

    <button mat-flat-button color="accent" mat-dialog-close type="button" (click)="close()">
      <i class="far fa-times-circle"></i>&nbsp;&nbsp;{{ 'request.dialog.close' | translate }}
    </button>
    <button *ngIf="eventId" mat-flat-button color="warn" mat-dialog-close type="button" (click)="delete(eventId)">
      <i class="fas fa-trash-alt"></i>&nbsp;&nbsp;{{ 'request.dialog.delete' | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit">
      <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ 'request.dialog.save' | translate }}
    </button>

  </mat-dialog-actions>

</form>
