import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {RequestSerializer} from '../model/request.serializer';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Request} from '../model/request.model';
import {DateSerializer} from '../../../shared/model/date-serializer';
import {map} from 'rxjs/operators';
import {WorkerSerializer} from '../../../shared/model/worker/worker.serializer';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private serviceURL = environment.request_service_URL + 'requests';
  private requestSerializer: RequestSerializer = new RequestSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();
  private workerSerializer: WorkerSerializer = new WorkerSerializer();

  constructor(private http: HttpClient) {
  }

  get(id: number) {
    return this.http.get<Request>(this.serviceURL + '/app/customer/load/' + id).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  filter(startDate: Date, endDate: Date): Observable<any> {
    let params = new HttpParams();
    params = params.append('startDate', this.dateSerializer.toJson(startDate));
    params = params.append('endDate', this.dateSerializer.toJson(endDate));
    return this.http.get<Request[]>(this.serviceURL + '/app/customer/list', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  getPastWorkers(): Observable<any> {
    return this.http.get<Request[]>(this.serviceURL + '/app/customer/list-past-workers').pipe(
      map((data) => data.map(request => this.workerSerializer.fromJson(request)))
    );
  }

  save(request: Request): Observable<any> {
    const url = this.serviceURL + '/app/customer/create-request';
    return this.http.post<Request>(url, this.requestSerializer.toJson(request)).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  delete(request: Request): Observable<any> {
    return this.http.delete<Request>(this.serviceURL + '/' + request.id);
  }

  private convertData(data: any): Request[] {
    return data.map(request => this.requestSerializer.fromJson(request));
  }
}
