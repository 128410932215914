export class User {
  id: number;
  username: string;
  languageCode: LanguageCode;
  password: string;
  role: Role;
  operatingUnitName: string;
}


export enum LanguageCode {
  FR = 'FR',
  NL = 'NL',
  EN = 'EN'
}

export enum Role {
  CUSTOMER_VALIDATOR = 'CUSTOMER_VALIDATOR',
  CUSTOMER_PLANNER = 'CUSTOMER_PLANNER',
  COMEXIS_SUPER_ADMIN = 'COMEXIS_SUPER_ADMIN'
}
