<h1 mat-dialog-title>Error</h1>
<div mat-dialog-content>
	<p class="error-message">
		{{data?.message}}
	</p>
</div>
<div mat-dialog-actions>
	<button mat-raised-button mat-dialog-close class="closeButton">
    close
  </button>
</div>
