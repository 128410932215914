import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { PrestationPeriod } from '../shared/model/prestation-period.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FacadeService } from '../../shared/service/facade/facade.service';
import { Constants } from '../../shared/model/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Prestation } from '../shared/model/prestation.model';

class PrestationFormModel {
	startDate: Date;
	startHour: Date;
	endDate: Date;
	endHour: Date;
	breakDuration: Date;
	totalWorkedHours: number;

	constructor(period: PrestationPeriod) {
		if (period.isWorkerValidated && !period.isCustomerValidated) {
			this.startDate = new Date(period.actualWorkerStartDate);
			this.startHour = new Date(period.actualWorkerStartDate);
			this.endDate = new Date(period.actualWorkerEndDate);
			this.endHour = new Date(period.actualWorkerEndDate);
			this.breakDuration = new Date(period.actualWorkerPauseTime);
		} else {
			this.startDate = period.plannedStartDate;
			this.startHour = period.plannedStartDate;
			this.endDate = period.plannedEndDate;
			this.endHour = period.plannedEndDate;
			this.breakDuration = period.plannedPauseTime;
		}
		this.totalWorkedHours = period.totalHoursToNumber;
	}
}

@Component({
	selector: 'app-prestation-dialog',
	templateUrl: './prestation-dialog.component.html',
	styleUrls: ['./prestation-dialog.component.scss']
})
export class PrestationValidationDialogComponent {
	clicked = false;
	prestationPeriod: PrestationPeriod;
	prestation: Prestation;
	prestationFormModel: PrestationFormModel;
	breakDurationValues: string[] = [
		'00:00', '00:15', '00:30', '00:45',
		'01:00', '01:15', '01:30', '01:45',
		'02:00', '02:15', '02:30', '02:45',
		'03:00', '03:15', '03:30', '03:45',
		'04:00', '04:15', '04:30', '04:45',
		'05:00', '05:15', '05:30', '05:45',
		'06:00', '06:15', '06:30', '06:45',
		'07:00', '07:15', '07:30', '07:45',
		'08:00'];

	constructor(private dialogRef: MatDialogRef<PrestationValidationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private facadeService: FacadeService,
		private translate: TranslateService,
		private datePipe: DatePipe,
		private snackBar: MatSnackBar) {
		this.prestation = data.prestation;
		this.prestationPeriod = data.prestationPeriod;
		this.prestationFormModel = new PrestationFormModel(data.prestationPeriod);
	}

	closeDialog(updated = false): void {
		this.dialogRef.close(updated);
	}

	onValidate(form: NgForm) {
		if (form.valid) {

			this.clicked = true;

			//  Compute Start Hour
			const startHour = form.value.startHour;
			let hours = startHour.split(':');
			const actualStartDate = new Date(form.value.startDate);
			actualStartDate.setHours(+hours[0]);
			actualStartDate.setMinutes(+hours[1]);

			//  Compute End Hour
			const endHour = form.value.endHour;
			hours = endHour.split(':');
			const actualEndDate = new Date(form.value.endDate);
			actualEndDate.setHours(+hours[0]);
			actualEndDate.setMinutes(+hours[1]);

			//  Compute Break Duration
			const breakDuration = form.value.breakDuration;
			hours = breakDuration.split(':');
			const breakDurationDate = new Date(0, 0, 0);
			breakDurationDate.setHours(+hours[0]);
			breakDurationDate.setMinutes(+hours[1]);

			const actualStartDate_ms = actualStartDate.getTime();
			const actualEndDate_ms = actualEndDate.getTime();
			const breakDuration_ms = breakDurationDate.getTime() - new Date(0, 0, 0, 0, 0, 0).getTime();
			const diff_ms = actualEndDate_ms - actualStartDate_ms - breakDuration_ms;


			if (!actualEndDate_ms || !actualStartDate_ms) {
				this.clicked = false;
				return this.openSnackBar(
					this.translate.instant('prestations.validation.dialog.message.error.invalidDate'), 'customSnackError');
			}

			//  Check if date are valid
			if (actualEndDate_ms < actualStartDate_ms) {
				this.clicked = false;
				return this.openSnackBar(
					this.translate.instant('prestations.validation.dialog.message.error.invalidHours'), 'customSnackError');
			}

			if (diff_ms > Constants.EVENT_MAXIMUM_DURATION) {
				this.clicked = false;
				return this.openSnackBar(
					this.translate.instant('prestations.validation.dialog.message.error.maximum'), 'customSnackError');
			}

			if (diff_ms < Constants.EVENT_MINIMUM_DURATION) {
				this.clicked = false;
				return this.openSnackBar(
					this.translate.instant('prestations.validation.dialog.message.error.minimum'), 'customSnackError');
			}

			const prestationPeriod: PrestationPeriod = this.data.prestationPeriod;

			prestationPeriod.actualCustomerStartDate = actualStartDate;
			prestationPeriod.actualCustomerEndDate = actualEndDate;
			prestationPeriod.actualCustomerPauseTime = breakDurationDate;

			if (this.data.nursing && prestationPeriod.isWorkerValidated) {
				let message = '';
				if (prestationPeriod.actualWorkerStartDate.getHours() !== prestationPeriod.actualCustomerStartDate.getHours() ||
					prestationPeriod.actualWorkerStartDate.getMinutes() !== prestationPeriod.actualCustomerStartDate.getMinutes()) {
					message = this.translate.instant('prestations.validation.dialog.message.error.mismatchStartHour', 'customSnackError');
				}
				if (prestationPeriod.actualWorkerEndDate.getHours() !== prestationPeriod.actualCustomerEndDate.getHours() ||
					prestationPeriod.actualWorkerEndDate.getMinutes() !== prestationPeriod.actualCustomerEndDate.getMinutes()) {
					message = this.translate.instant('prestations.validation.dialog.message.error.mismatchEndHour', 'customSnackError');
				}
				if (message !== '' ? confirm(message) : true) {
					this.validatePrestationPeriod(prestationPeriod);
				} else {
					this.clicked = false;
				}
			} else {
				this.validatePrestationPeriod(prestationPeriod);
			}
		}
	}

	private validatePrestationPeriod(period: PrestationPeriod) {
		this.facadeService.validatePrestationPeriod(period).subscribe({
			next: ignoreProperty => {
				return this.closeDialog(true);
			},
			error: ignoreProperty => {
				this.openSnackBar(this.translate.instant(
					'prestations.validation.dialog.message.error.validatePrestationPeriod',
					'customSnackError')
				);
			}
		});
	}

	// Check if startDate =/= endDate 
	isNextDay(formValue: NgForm) {
		const startHour = formValue.value.startHour;
		const endHour = formValue.value.endHour;
		const resultStart = startHour.match(/([0-9]{1,2}):[0-9]{2}/);
		const resultEnd = endHour.match(/([0-9]{1,2}):[0-9]{2}/);
		if (resultStart && resultEnd) {
			return resultStart[1] > resultEnd[1] ? true : false;
		}
	}
	
	// On blur methods
	onBlurStartHour(formValue: NgForm) {
		this.changeStartHour(formValue)
	}

	onBlurEndHour(formValue: NgForm) {
		this.changeEndHour(formValue)
	}

	onBlurBreakDuration(formValue: NgForm) {
		this.setTotalHour(formValue)
	}

	// Change fields value & total hours
	changeStartHour(formValue: NgForm) {
		this.changeEndDate(formValue);
		formValue.controls['endDate'].setValue(this.datePipe.transform(this.prestationFormModel.endDate, 'yyyy-MM-dd'));
		this.setTotalHour(formValue);
	}

	changeEndHour(formValue: NgForm) {
		this.changeEndDate(formValue);
		formValue.controls['endDate'].setValue(this.datePipe.transform(this.prestationFormModel.endDate, 'yyyy-MM-dd'));
		this.setTotalHour(formValue);
	}

	changeBreakDuration(formValue: NgForm) {
		this.setTotalHour(formValue);
	}

	// If startDate =/= startDate, then endDate = startDate + 1
	changeEndDate(formValue: NgForm) {
		let startDate = this.prestationFormModel.startDate;
		let endDate = this.prestationFormModel.endDate;

		this.isNextDay(formValue) ? endDate.setDate(startDate.getDate() + 1) : endDate.setDate(startDate.getDate());	
			
	}

	// Compute total hours
	setTotalHour(formValue: NgForm) {
		const startHour = this.getMinutesFromString(formValue.value.startHour);
		const endHour = this.getMinutesFromString(formValue.value.endHour);
		const breakDuration = formValue.value.breakDuration != null ? this.getMinutesFromString(formValue.value.breakDuration) : 0.0;

		// If startDate =/= endDate, then "(24 - startDate) + endate"
		const diff = this.isNextDay(formValue) ? 1440 - startHour + endHour - breakDuration : endHour - startHour - breakDuration;
		this.prestationFormModel.totalWorkedHours = parseFloat(Math.floor(diff / 60) + '.' + diff % 60);
	}

	getMinutesFromString(dateToChange: string): number {
		return +dateToChange.split(':')[0] * 60 + +dateToChange.split(':')[1];
	}

	openSnackBar(message: string, pC: string = 'customSnackValid') {
		this.snackBar.open(message, 'X', {
			duration: 5000,
			panelClass: [pC]
		});
	}
}
