import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {LoginComponent} from './login/login.component';
import {CustomerComponent} from './customer/customer.component';
import {PlanningComponent} from './planning/planning.component';
import {ContractsComponent} from './contracts/contracts.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {PrestationsComponent} from './prestations/prestations.component';
import {UserComponent} from './user/user.component';
import {RequestComponent} from './request/request.component';
import {PrestationsToValidateResolver} from './shared/resolver/prestation/prestations-to-validate.resolver';
import {ValidatorGuardService} from './login/shared/service/validator-guard.service';
import {RegisterComponent} from './register/register.component';
import {AuthorizationService} from './login/shared/service/authorization.service';
import {RequestResetPasswordComponent} from './reset-password/request-reset-password/request-reset-password.component';
import {EditPasswordComponent} from './reset-password/edit-password/edit-password.component';
import {UserByCodeResolver} from './shared/resolver/user/user-by-code.resolver';
import {RequestResolver} from './shared/resolver/request/request.resolver';
import {CustomerResolver} from './shared/resolver/customer/customer.resolver';

const routes: Routes = [
  //  ---------- Open Access | Start ----------
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'send-password-reset',
    component: RequestResetPasswordComponent
  },
  {
    path: 'reset-password',
    component: EditPasswordComponent,
    resolve: {
      user: UserByCodeResolver
    }
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  //  ---------- Open Access | End   ----------

  //  ---------- Secure Access | Start   ----------
  {
    path: 'profile',
    component: CustomerComponent,
    canActivate: [AuthorizationService],
    resolve: {
      customer: CustomerResolver
    }
  },
  {
    path: 'planning',
    component: PlanningComponent,
    canActivate: [AuthorizationService],
    resolve: {
      customer: CustomerResolver
    }
  },
  {
    path: 'contracts',
    component: ContractsComponent,
    canActivate: [AuthorizationService]
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthorizationService]
  },
  {
    path: 'prestations',
    component: PrestationsComponent,
    resolve: {
      prestations: PrestationsToValidateResolver,
    },
    canActivate: [AuthorizationService, ValidatorGuardService]
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthorizationService]
  },
  {
    path: 'request',
    component: RequestComponent,
    resolve: {
      request: RequestResolver,
      customer: CustomerResolver,
    },
    canActivate: [AuthorizationService]
  },
  //  ---------- Secure Access | End   ----------

  //  Not Found Redirect
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
