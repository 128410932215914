import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WorkFunction } from '../shared/model/work-function/work-function.model';
import { FacadeService } from '../shared/service/facade/facade.service';
import { AuthorizationService } from '../login/shared/service/authorization.service';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { MatDialog } from '@angular/material/dialog';
import { EventDialogComponent } from './event-dialog/event-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Request } from './shared/model/request.model';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Worker } from '../shared/model/worker/worker.model';
import { CalendarOptions, DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { AppComponent } from '../app.component';
import { FullCalendarComponent } from '@fullcalendar/angular';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit, AfterViewInit {
  private readonly EVENT_MINIMUM_DURATION = 10800000; //  3 hours
  private readonly EVENT_MAXIMUM_DURATION = 39600000; //  11  hours
  costCenterList: string[] = [];
  workFunctionList: WorkFunction[] = [];
  workerList: Worker[] = [];
  form: UntypedFormGroup;
  @Input() request: Request;

  @ViewChild('calendar') calendarRef: FullCalendarComponent;
  requestCalendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'timeGridWeek',
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: true,
    allDaySlot: false,
    nowIndicator: true,
    locale: this.translate.currentLang,
    select: this.handleDateSelect.bind(this),
    eventResize: this.handleResize.bind(this),
    eventClick: this.handleEventClick.bind(this),
  };
  calendarOptions: CalendarOptions;
  modifiable = true;

  constructor(private facadeService: FacadeService,
    private authorizationService: AuthorizationService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private location: Location) {

    this.request = this.route.snapshot.data['request'];
    this.costCenterList = this.route.snapshot.data['customer'].costCenter;

    if (!this.request) {
      this.request = new Request();
    }

    this.modifiable = !this.request.id || this.request.isModifiable;

    this.calendarOptions = {
      ...AppComponent.calendarOptions,
      ...this.requestCalendarOptions,
      selectable: this.modifiable,
      editable: this.modifiable,
      validRange: {
        start: this.modifiable ? new Date() : null
      },
    };
  }

  ngOnInit() {
    this.buildForm();
    this.getWorkFunctions();
    this.getWorkers();
  }


  ngAfterViewInit() {
    if (this.request) {

      this.request.periods.forEach(
        period => {
          const event = {
            id: period.id.toString(),
            start: period.startDate,
            end: period.endDate
          };
          if (period.description) {
            event['title'] = period.description;
            event['description'] = period.description;
          }
          this.calendarRef.getApi().addEvent(event);
        }
      );

      this.calendarRef.getApi().gotoDate(this.request.startDate);
    }
  }

  onSubmit() {
    if (!this.modifiable) {
      return this.router.navigate(['planning']);
    }

    let periods = this.form.controls.periods as UntypedFormArray;
    let events = this.calendarRef.getApi().getEvents();
    events.forEach(
      event => {
        periods.push(this.buildPeriodForm(event.start, event.end, event.title));
      }
    );

    if (!this.form.valid) {
      periods.clear();
      return this.openSnackBar(this.translate.instant('request.message.error.form'));
    }

    const min = new Date(events.reduce((a, p) => p.start.getTime() < a ? p.start.getTime() : a, events[0].start.getTime()));
    const max = new Date(events.reduce((a, p) => p.end.getTime() > a ? p.end.getTime() : a, events[0].end.getTime()));

    const request = this.form.value as Request;
    request.startDate = min;
    request.endDate = max;

    request.worker = new Worker();
    request.workFunctionId = this.form.controls.workFunctionId?.value;

    request.worker.id = this.form.controls.workerId.value;

    this.facadeService.saveRequest(request).subscribe({
      next: ignore => {
        this.router.navigate(['planning']).then();
        this.openSnackBar(this.translate.instant('request.message.success.save'), 'customSnackValid');
      },
      error: ignore => {
        this.openSnackBar(this.translate.instant('request.message.error.save'));
      }
    });
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (this.modifiable) {
      this.openEventDialog(clickInfo.event.start, clickInfo.event.end, clickInfo.event.extendedProps.description, clickInfo.event.id);
    }
  }

  handleResize(eventResizeInfo: EventResizeDoneArg) {
    const start = new Date(eventResizeInfo.event.start);
    const end = new Date(eventResizeInfo.event.end);

    const answer = this.checkEventValidity(start, end);

    if (!answer && this.modifiable) {
      eventResizeInfo.revert();
    }
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const start = new Date(selectInfo.startStr);
    const end = new Date(selectInfo.endStr);

    const answer = this.checkEventValidity(start, end);

    if (answer && this.modifiable) {
      this.openEventDialog(selectInfo.start, selectInfo.end);
    }
  }

  private openEventDialog(start: Date, end: Date, description: string = null, eventId: any = null) {
    const dialogRef = this.dialog.open(EventDialogComponent, {
      data: {
        description: description,
        eventId: eventId
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      //  Result[0]: Description
      //  Result[1]: Is a delete ?
      const calendarApi = this.calendarRef.getApi().view.calendar;
      calendarApi.unselect(); // clear date selection
      if (result && result[1]) {
        return this.deleteEvent(eventId);
      }
      if (result) {
        if (eventId) {
          this.deleteEvent(eventId);
        }

        this.checkEventValidity(start, end);

        const event = {
          id: Math.random().toString(36).slice(2, 9),
          start: start,
          end: end
        };
        if (result[0]) {
          event['title'] = result[0];
          event['description'] = result[0];
        }
        calendarApi.addEvent(event);
      }

    });
  }

  private deleteEvent(eventId: any) {
    const event = this.calendarRef.getApi().getEventById(eventId);
    if (event) {
      event.remove();
    }
  }


  deleteRequest(request: Request) {

    // Check if request's first period's start date is < new Date() so that it can still be deleted //
    const sortedPeriods = request.periods.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
    const firstStartDate = sortedPeriods[0].startDate;

    if (firstStartDate < new Date()) {
      return;
    } else {
      this.facadeService.deleteRequest(request).subscribe({
        next: () => {
          this.openSnackBar(this.translate.instant('request.message.success.delete'), 'customSnackValid');
          this.returnToPlanning();
        },
        error: () => {
          return this.openSnackBar(this.translate.instant('request.message.error.delete'), 'customSnackError');
        }
      });
    }
  }

  private checkEventValidity(start: Date, end: Date) {
    const diff = end.getTime() - start.getTime();

    if (start.getTime() < Date.now()) {
      this.openSnackBar(this.translate.instant('request.message.error.checkEventValidity.past'));
      return false;
    }

    if (diff < this.EVENT_MINIMUM_DURATION) {
      this.openSnackBar(this.translate.instant('request.message.error.checkEventValidity.minimum'));
      return false;
    }

    if (diff > this.EVENT_MAXIMUM_DURATION) {
      this.openSnackBar(this.translate.instant('request.message.error.checkEventValidity.maximum'));
      return false;
    }

    return true;
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      id: [this.request.id, []],
      costCenter: [{ value: this.request.costCenter, disabled: !this.modifiable }, []],
      workFunctionId: [{ value: this.request.workFunction?.id, disabled: !this.modifiable }, [Validators.required]],
      workerId: [{ value: this.request.worker ? this.request.worker.id : null, disabled: !this.modifiable }, []],
      periods: this.formBuilder.array([], [Validators.required]),
    });
  }

  private buildPeriodForm(start: Date, end: Date, title: string) {
    return this.formBuilder.group({
      description: [(title ? title : null), []],
      startDate: [start, [Validators.required]],
      endDate: [end, [Validators.required]],
    });
  }

  private getWorkFunctions() {
    this.facadeService.listWorkFunctions().subscribe({
      next: (workFunctions: WorkFunction[]) => {
        this.workFunctionList = workFunctions;

        this.workFunctionList = this.workFunctionList.sort(function (a, b) {
          return a.nameTranslate.localeCompare(b.nameTranslate);
        }
        )
      },
      error: ignore => {
        this.openSnackBar(this.translate.instant('request.message.error.workFunctions'));
      }
    });
  }

  private getWorkers() {
    this.facadeService.getPastWorkers().subscribe({
      next: (worker: Worker[]) => {
        this.workerList = worker;
      },
      error: ignore => {
        this.openSnackBar(this.translate.instant('request.message.error.workFunctions'));
      }
    });
  }

  private openSnackBar(msg: string, pC: string = 'customSnackError') {
    this.snackBar.open(msg, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }

  returnToPlanning() {
    return this.router.navigate(['planning']);
  }

}
