export class Country {
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
}

export class Address {
  street: string;
  number: string;
  box: string;
  zip: string;
  city: string;
  country: string;

  constructor(address?: Address) {
    if (address) {
      this.street = address.street;
      this.number = address.number;
      this.box = address.box;
      this.zip = address.zip;
      this.city = address.city;
      this.country = address.country;
    }
  }

  get formattedAddress() {
    let address = `${this.street ? this.street : ''}, ${this.number ? this.number : ''}, `;
    address += `${this.box ? this.box : ''} - ${this.country ? this.country : ''} `;
    address += `${this.zip ? this.zip : ''} ${this.city ? this.city : ''}`;
    address = address.replace(/,\s*,/, ',');
    address = address.replace(/,\s*-/, ' - ');
    return address;
  }
}
