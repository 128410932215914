import {PrestationPeriod} from './prestation-period.model';
import {Serializer} from '../../../shared/model/serializer.interface';
import {DateSerializer} from '../../../shared/model/date-serializer';

export class PrestationPeriodSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): PrestationPeriod {
    const period = new PrestationPeriod();

    // Reading properties
    period.id = json.id;
    period.plannedStartDate = this.dateSerializer.fromJson(json.plannedStartDate);
    period.plannedEndDate = this.dateSerializer.fromJson(json.plannedEndDate);
    period.plannedPauseTime = this.dateSerializer.hoursFromJson(json.plannedPauseTime);
    period.actualStartDate = this.dateSerializer.fromJson(json.actualStartDate);
    period.actualEndDate = this.dateSerializer.fromJson(json.actualEndDate);
    period.actualPauseTime = this.dateSerializer.hoursFromJson(json.actualPauseTime);
    period.actualWorkerStartDate = this.dateSerializer.fromJson(json.actualWorkerStartDate);
    period.actualWorkerEndDate = this.dateSerializer.fromJson(json.actualWorkerEndDate);
    period.actualWorkerPauseTime = this.dateSerializer.hoursFromJson(json.actualWorkerPauseTime);
    period.actualCustomerStartDate = this.dateSerializer.fromJson(json.actualCustomerStartDate);
    period.actualCustomerEndDate = this.dateSerializer.fromJson(json.actualCustomerEndDate);
    period.actualCustomerPauseTime = this.dateSerializer.hoursFromJson(json.actualCustomerPauseTime);
    period.justificationFileId = json.justificationFileId;
    period.description = json.description;

    period.isProcessed = json.processed;
    period.isDateNotMatched = json.dateNotMatched;
    period.isWorkerValidated = json.workerValidated;
    period.isWorkerRejected = json.workerRejected;
    period.isCustomerValidated = json.customerValidated;
    period.isCustomerRejected = json.customerRejected;
    period.isValidated = json.validated;
    period.isRejected = json.rejected;
    period.isModifiable = json.modifiable;
    period.isMissMatched = json.missMatched;
    period.totalHoursToNumber = json.totalHoursToNumber;

    return period;
  }

  toJson(period: PrestationPeriod): any {

    return {
      id: period.id,
      description: period.description,
      customerJustificationCode: period.customerJustificationCode,
      actualCustomerStartDate: this.dateSerializer.dateWithTimeToJson(period.actualCustomerStartDate),
      actualCustomerEndDate: this.dateSerializer.dateWithTimeToJson(period.actualCustomerEndDate),
      actualCustomerPauseTime: this.dateSerializer.hoursToJson(period.actualCustomerPauseTime)
    };
  }
}
