import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {Role, User} from '../../../user/shared/model/user.model';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Customer} from 'src/app/customer/shared/model/customer.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService implements CanActivate {
  private tokenKey = 'hs-customer-app.token';
  private userKey = 'hs-customer-app.user';
  private refreshTokenKey = 'hs-customer-app.refresh-token';
  private storage: Storage = sessionStorage;
  private localStorage: Storage = localStorage;


  constructor(private router: Router,
              private http: HttpClient,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private facadeService: FacadeService) {
  }

  async canActivate(): Promise<boolean> {
    if (!this.checkIfLogged()) {
      await this.router.navigateByUrl('/login');
      return false;
    }

    return true;
  }

  hasRole(role: Role): boolean {
    switch (role) {
      case Role.CUSTOMER_VALIDATOR:
        return this.loggedIn() && this.isCustomerValidator();
      case Role.COMEXIS_SUPER_ADMIN:
        return this.loggedIn() && this.isComexisSuperAdmin();
      default:
        return this.loggedIn() && this.isCustomerPlanner();
    }
  }

  loggedIn(): boolean {
    return !!(this.storage.getItem(this.tokenKey));
  }

  private isCustomerPlanner(): boolean {
    return this.getCurrentUser().role === Role.CUSTOMER_PLANNER || this.isCustomerValidator();
  }

  private isCustomerValidator(): boolean {
    return this.getCurrentUser().role === Role.CUSTOMER_VALIDATOR || this.isComexisSuperAdmin();
  }

  private isComexisSuperAdmin(): boolean {
    return this.getCurrentUser().role === Role.COMEXIS_SUPER_ADMIN;
  }

  async setUser(token: string, refreshToken: string, rememberMe: boolean) {

    const user: User = await firstValueFrom(this.facadeService.getUserFromToken(token))
      .catch(() => {
        this.displayErrorMessage();
      });

    if (![Role.COMEXIS_SUPER_ADMIN, Role.CUSTOMER_VALIDATOR, Role.CUSTOMER_PLANNER].includes(user.role)) {
      this.displayErrorMessage();
    }
    this.setCurrentUser(user);
    this.setToken(token, refreshToken);

    const customer: Customer = await firstValueFrom(this.facadeService.getCustomer())
      .catch(() => {
        this.displayErrorMessage();
      });

    user.operatingUnitName = customer.operatingUnit.name;
    this.setCurrentUser(user);

    if (rememberMe) {
      this.localStorage.setItem(this.refreshTokenKey, refreshToken);
    }

    window.location.href = '/profile';
  }

  async autoLogin() {
    if (!this.getCurrentUser()) {
      const refreshToken = this.localStorage.getItem(this.refreshTokenKey);
      if (!!(refreshToken)) {
        const httpResponse = await firstValueFrom(this.facadeService.refreshToken(refreshToken))
          .catch(() => {
            this.displayErrorMessage();
          });
        await this.setUser(httpResponse.headers.get('authorization'), httpResponse.headers.get('refresh_token'), true);
      }
    }
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.facadeService.refreshToken(refreshToken);
  }

  getCurrentUser(): User {
    return JSON.parse(this.storage.getItem(this.userKey));
  }

  setCurrentUser(user: User): void {
    this.storage.setItem(this.userKey, JSON.stringify(user));
  }

  checkIfLogged(): boolean {
    return !!(this.storage.getItem(this.tokenKey));
  }

  setToken(newToken: string, refreshToken: string): void {

    if (this.getCurrentUser()) {
      this.storage.setItem(this.tokenKey, newToken);
      this.storage.setItem(this.refreshTokenKey, refreshToken);

      if (!!(this.localStorage.getItem(this.refreshTokenKey))) {
        this.localStorage.setItem(this.refreshTokenKey, refreshToken);
      }
    }
  }

  getToken(): string {
    return this.storage.getItem(this.tokenKey);
  }

  getRefreshToken(): string {
    return this.storage.getItem(this.refreshTokenKey);
  }

  logout(): void {
    this.deleteStorage();
    window.location.href = '/login';
  }

  private deleteStorage() {
    this.storage.removeItem(this.tokenKey);
    this.storage.removeItem(this.userKey);
    this.storage.removeItem(this.refreshTokenKey);
    this.localStorage.removeItem(this.refreshTokenKey);

  }

  private displayErrorMessage() {
    this.snackBar.open(this.translate.instant('authorization.message.error.login'), 'X', {
      duration: 5000,
      panelClass: ['customSnackError']
    });
    this.logout();
  }
}
