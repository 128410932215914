import {NgModule} from '@angular/core';
import {PrestationsToValidateResolver} from './shared/resolver/prestation/prestations-to-validate.resolver';
import {UserByCodeResolver} from './shared/resolver/user/user-by-code.resolver';
import {RequestResolver} from './shared/resolver/request/request.resolver';
import {CustomerResolver} from './shared/resolver/customer/customer.resolver';

@NgModule({
  providers: [
    CustomerResolver,
    PrestationsToValidateResolver,
    UserByCodeResolver,
    RequestResolver
  ]
})
export class ResolverModule {
}
