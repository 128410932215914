import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PrestationSerializer} from '../model/prestation.serializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Prestation} from '../model/prestation.model';

@Injectable({
  providedIn: 'root'
})
export class PrestationService {
  private serviceURL = environment.prestation_service_URL + 'prestations';
  private prestationSerializer: PrestationSerializer = new PrestationSerializer();

  constructor(private http: HttpClient) {
  }

  listToValidate(): Observable<any> {
    return this.http.get<Prestation[]>(this.serviceURL + '/customer/to-validate').pipe(
      map((data) => this.convertData(data))
    );
  }

  validate(prestation: Prestation): Observable<any> {
    return this.http.post<Prestation>(this.serviceURL + `/customer/validate/${prestation.id}`, {}).pipe(
      map((data) => this.prestationSerializer.fromJson(data))
    );
  }

  private convertData(data: any): Prestation[] {
    return data.map(prestation => this.prestationSerializer.fromJson(prestation));
  }
}
