<form #validateForm="ngForm" (ngSubmit)="onValidate(validateForm)">

  <h5 mat-dialog-title><i
    class="fas fa-check"></i>&nbsp;#{{prestationPeriod.id}}&nbsp;&nbsp;{{prestation.customerName}}&nbsp;-&nbsp;{{prestation.workerLastName}}&nbsp;{{prestation.workerFirstName}}
  </h5>

  <mat-dialog-content *ngIf="prestationPeriod.isProcessed">
    <div class="row-form">
      <div class="col">
        <p>{{ 'prestations.validation.dialog.alreadyValidated' | translate }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="!prestationPeriod.isProcessed">

    <div class="form-row">
      <div class="col">
        <div class="matFlex">
          <mat-form-field appearance="outline">

            <mat-label>{{ 'prestations.validation.dialog.justification' | translate }}</mat-label>
            <mat-select  name="customerJustificationCode" id="customerJustificationCode" required
                         [ngModel]="prestationPeriod.customerJustificationCode">
              <mat-option *ngFor="let prestationCode of prestationCodes;"
                          [value]="prestationCode?.code">
                {{ getTranslation(prestationCode)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="matFlex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'prestations.validation.dialog.description' | translate }}</mat-label>
            <textarea maxlength="255" matInput
                      cdkTextareaAutosize
                      placeholder="{{ 'prestations.validation.dialog.comment' | translate }}" id="description"
                      name="description" [ngModel]="prestationPeriod.description"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-row pb-3">
      <div class="col">
        <div>
          <input ngModel name="justificationFile" id="justificationFile" type="file" required
                 (change)="handleFileInput($event.target)"/>
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="mb-1 justify-content-between">

    <button mat-flat-button type="button" color="accent" mat-dialog-close="false" (click)="closeDialog()">
      <i class="far fa-times-circle"></i>&nbsp;&nbsp;{{ 'prestations.validation.dialog.close' | translate }}
    </button>
    <button mat-flat-button color="warn" type="submit" cdkFocusInitial [disabled]="clicked">
      <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ 'prestations.validation.dialog.reject' | translate }}
    </button>

  </mat-dialog-actions>

</form>
