import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../shared/service/facade/facade.service';
import {User} from '../user/shared/model/user.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  form: UntypedFormGroup;
  private readonly uuid: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private facadeService: FacadeService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService) {
    this.uuid = this.route.snapshot.queryParamMap.get('uuid');
  }

  async ngOnInit() {
    const language = this.route.snapshot.queryParamMap.get('language');
    if (language) {
      this.translate.use(language.toLowerCase());
    }
    this.buildForm();
    await this.getCustomerByCode();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      username: [{value: null, disabled: true}, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  private async getCustomerByCode() {
    if (!this.uuid) {
      await this.throwError();
    }

    this.facadeService.getUserByUuid(this.uuid).subscribe({
        next:
          (data: User) => {
            if (!data) {
              return this.throwError();
            }
            this.form.controls.username.setValue(data.username);
          },
        error: () => {
          this.throwError();
        }
      }
    );
  }

  onSubmit() {

    if (this.form.invalid) {
      return this.openSnackBar(this.translate.instant('register.message.error.invalid'), 'customSnackError');
    }

    const password = this.form.controls.password.value.trim();
    const confirmPassword = this.form.controls.confirmPassword.value.trim();
    const username = this.form.controls.username.value.trim();

    if (password !== confirmPassword) {
      return this.openSnackBar(this.translate.instant('register.message.error.password'), 'customSnackError');
    }

    this.facadeService.updateUserPassword('', password, username, this.uuid).subscribe({
      next:
        ignore => {
          this.openSnackBar(this.translate.instant('register.message.success.account'));
          this.router.navigate([`/login`], {replaceUrl: true}).then();
        },
      error: error => {
        this.throwError(error).then();
      }
    });
  }

  private throwError(error?) {
    if (error.error.status === 409) {
      this.openSnackBar(this.translate.instant('register.message.error.alreadyExists'), 'customSnackError');
    } else {
      this.translate.get('register.message.error.code').subscribe((res: string) => {
        this.openSnackBar(error.message ? error.error.message : res, 'customSnackError');
      });
      return this.router.navigate(['login']);
    }
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }
}
