import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Role, User } from './user/shared/model/user.model';
import { AuthorizationService } from './login/shared/service/authorization.service';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import nlLocale from '@fullcalendar/core/locales/nl';
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService,
    private authService: AuthorizationService,
    public router: Router) {
    const defaultLanguage = window.navigator.language.split('-')[0].toLowerCase();
    translate.setDefaultLang(defaultLanguage ? defaultLanguage : 'EN');
    this.useLanguage(defaultLanguage ? defaultLanguage : 'EN');
  }

  static calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    firstDay: 1,
    height: 'auto',
    locales: [enLocale, frLocale, nlLocale],
    timeZone: 'local',
  };

  isLoggedIn: boolean;
  currentUser: User;

  ngOnInit() {
    this.isLoggedIn = this.authService.checkIfLogged();
    if (this.isLoggedIn) {
      this.currentUser = this.authService.getCurrentUser();
    }
  }

  onLogout() {
    this.authService.logout();
  }

  hasRole() {
    return this.authService.hasRole(Role.CUSTOMER_VALIDATOR);
  }

  getLanguage() {
    return this.translate.currentLang.toUpperCase();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

}
