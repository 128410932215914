<div class="container-fluid shadowbox">
  <div class="grid">

    <div class="col">

      <div class="row">

        <!-- LEGAL DATA  -->
        <div class="col-xl-6 col-xs-12">

          <h5 class="heading mt-2 mb-4">{{ 'customer.legal-data.title' | translate }}</h5>

          <div class="form-group">

            <div class="form-row">

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.prefix' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer.prefix}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-3">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.enterpriseNumber' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.enterpriseNumber}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-7">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.name' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.name}}" readonly>
                  </mat-form-field>
                </div>

              </div>

            </div>

            <div class="form-row">

              <div class="col-8">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.address.street' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.address?.street}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.address.number' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.address?.number}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.address.box' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.address?.box}}" readonly>
                  </mat-form-field>
                </div>

              </div>

            </div>

            <div class="form-row">

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.address.zip' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.address?.zip}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-5">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.address.city' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.address?.city}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-5">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.legal-data.address.country' | translate }}</mat-label>
                    <input matInput type="text" value="{{countryName}}" readonly>
                  </mat-form-field>
                </div>

              </div>

            </div>

          </div>

        </div>

        <!-- BILLING  -->
        <div class="col-xl-6 col-xs-12">

          <div class="form-group">

            <h5 class="heading mt-2 mb-4">{{ 'customer.billing-data.title' | translate }}</h5>

            <div class="form-row">

              <div class="col-8">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.billing-data.address.street' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.billingAddress?.street}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.billing-data.address.number' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.billingAddress?.number}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.billing-data.address.box' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.billingAddress?.box}}" readonly>
                  </mat-form-field>
                </div>

              </div>

            </div>

            <div class="form-row">

              <div class="col-2">

                <div class="matFlex">
                  <mat-form-field class="mediumField" appearance="legacy">
                    <mat-label>{{ 'customer.billing-data.address.zip' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.billingAddress?.zip}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-5">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.billing-data.address.city' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.billingAddress?.city}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-5">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.billing-data.address.country' | translate }}</mat-label>
                    <input matInput type="text" value="{{billingCountryName}}" readonly>
                  </mat-form-field>
                </div>

              </div>

            </div>

          </div>

          <div class="form-group">

            <h5 class="heading mt-2 mb-4">{{ 'customer.cost-center' | translate }}</h5>

            <div class="form-row">

              <div class="col-12">

                <mat-form-field class="costCenter-chip-list">
                  <mat-chip-list aria-label="Cost Center selection">
                    <!-- if costCenter exist -->
                    <div  *ngIf="customer?.costCenter.toString()!==''">
                      <mat-chip color="x" class="chipNumber" *ngFor="let costCenter of customer?.costCenter">
                        {{costCenter}}
                      </mat-chip>
                    </div>
                    <!-- if costCenter is empty -->
                    <div  *ngIf="customer?.costCenter.toString()==''">
                      <mat-chip color="x" class="chipNumber">
                       {{ 'customer.noCost-center' | translate }}
                      </mat-chip>
                    </div>
                  </mat-chip-list>
                </mat-form-field>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="modifyProfileInfo col-xs-12">
        <p>&nbsp;* {{ 'customer.modifyProfileInfo' | translate }}</p>
      </div>

    </div>

  </div>

</div>

<!-- OPERATING UNIT  -->
<div class="container-fluid shadowbox">

  <h5 class="heading my-3">&nbsp;{{ 'customer.agency.title' | translate }}&nbsp;-&nbsp;{{customer?.operatingUnit?.name}}
  </h5>

  <div class="grid">

    <div class="col">

      <div class="row">

        <div class="col">

          <div class="form-group">

            <div class="form-row">

              <div class="col">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label><i class="fas fa-phone"></i>&nbsp;&nbsp;{{ 'customer.agency.phone' | translate }}
                    </mat-label>
                    <input matInput type="text" value="{{customer?.operatingUnit?.phone}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label><i class="fas fa-envelope"></i>&nbsp;&nbsp;{{ 'customer.agency.mail' | translate }}
                    </mat-label>
                    <input matInput type="text" value="{{customer?.operatingUnit?.mail}}" readonly>
                  </mat-form-field>
                </div>

              </div>

              <div class="col">

                <div class="matFlex">
                  <mat-form-field appearance="legacy">
                    <mat-label>{{ 'customer.agency.maps' | translate }}</mat-label>
                    <input matInput type="text" value="{{customer?.operatingUnit?.address?.formattedAddress}}" readonly>
                  </mat-form-field>
                </div>

              </div>

            </div>

            <div class="form-group">

              <table class="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">{{ 'customer.agency.monday' | translate }}</th>
                    <td>9h - 17h30</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'customer.agency.tuesday' | translate }}</th>
                    <td>9h - 17h30</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'customer.agency.wednesday' | translate }}</th>
                    <td>9h - 17h30</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'customer.agency.thursday' | translate }}</th>
                    <td>9h - 17h30</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'customer.agency.friday' | translate }}</th>
                    <td>9h - 17h30</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'customer.agency.saturday' | translate }}</th>
                    <td>{{ 'customer.agency.close' | translate }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'customer.agency.sunday' | translate }}</th>
                    <td>{{ 'customer.agency.close' | translate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</div>