import {Component, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {PrestationCode} from '../../shared/model/prestation-code/prestation-code.model';
import {PrestationPeriod} from '../shared/model/prestation-period.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Prestation} from '../shared/model/prestation.model';

class PrestationFormModel {
  startDate: Date;
  startHour: Date;
  endDate: Date;
  endHour: Date;

  constructor(prestation: PrestationPeriod) {
    this.startDate = new Date(prestation.plannedStartDate);
    this.startHour = new Date(prestation.plannedStartDate);
    this.endDate = new Date(prestation.plannedEndDate);
    this.endHour = new Date(prestation.plannedEndDate);
  }
}

@Component({
  selector: 'app-prestation-dialog',
  templateUrl: './prestation-dialog.component.html',
  styleUrls: ['./prestation-dialog.component.scss']
})
export class PrestationRejectDialogComponent {
  clicked = false;
  prestationReject = true;
  prestation: Prestation;
  prestationPeriod: PrestationPeriod;
  prestationCodes: PrestationCode[] = [];
  prestationFormModel: PrestationFormModel;
  fileToUpload: File = null;

  constructor(private dialogRef: MatDialogRef<PrestationRejectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private facadeService: FacadeService,
              private translate: TranslateService,
              private datePipe: DatePipe,
              private snackBar: MatSnackBar) {
    this.prestation = data.prestation;
    this.prestationPeriod = data.prestationPeriod;
    this.prestationCodes = data.prestationCodes;
    this.prestationFormModel = new PrestationFormModel(data.prestationPeriod);
  }

  closeDialog(updated = false): void {
    this.prestationReject = false;
    this.dialogRef.close(updated);
  }

  onValidate(form: NgForm) {
    this.clicked = true;
    if (form != null && form.value.customerJustificationCode != null) {

      //  Check if date are valid
      const prestationPeriod: PrestationPeriod = this.data.prestationPeriod;
      prestationPeriod.description = form.value.description;
      prestationPeriod.customerJustificationCode = form.value.customerJustificationCode;
      this.reject(prestationPeriod, this.fileToUpload);
    }
  }

  private reject(period: PrestationPeriod, file: File) {
    if (confirm(this.translate.instant('prestations.validation.dialog.message.confirm.reject'))) {
      this.facadeService.rejectPrestationPeriod(period, file).subscribe({
        next: ignoreProperty => {
          return this.closeDialog(true);
        },
        error: ignoreProperty => {
          this.openSnackBar(this.translate.instant('prestations.validation.dialog.message.error.reject', 'customSnackError'));
        }
      });
    } else {
      this.clicked = false;
    }
  }

  getTranslation(prestationCode: PrestationCode) {
    switch (this.translate.currentLang) {
      case 'fr': return prestationCode.labelFR;
      case 'nl': return prestationCode.labelNL;
      case 'de': return prestationCode.labelDE;
      case 'en': return prestationCode.labelEN;
      default: return prestationCode.labelEN;
    }
  }

  handleFileInput(target) {
    this.fileToUpload = target.files.item(0);
  }

  openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }
}
