<form #changePasswordForm="ngForm" (ngSubmit)="changePassword(changePasswordForm)">

  <div class="container-fluid shadowbox pb-3">

    <div class="grid">

      <div class="col">

        <div class="row">

          <div class="col-xl-12">

            <h5 class="heading mt-2 mb-4">{{ 'user.title' | translate }}</h5>

            <div class="form-group">

              <div class="form-row">

                <div class="col">

                  <div class="matFlex">

                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'user.actual' | translate }}</mat-label>
                      <input ngModel matInput name="oldPassword" id="oldPassword" required type="password"/>
                    </mat-form-field>

                  </div>

                </div>

              </div>

              <div class="form-row">

                <div class="col-6">

                  <div class="matFlex">

                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'user.new' | translate }}</mat-label>
                      <input ngModel matInput name="newPassword" id="newPassword" required type="password"/>
                    </mat-form-field>

                  </div>

                </div>

                <div class="col">

                  <div class="matFlex">

                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'user.confirm' | translate }}</mat-label>
                      <input ngModel matInput name="confirmPassword" id="confirmPassword" required type="password"/>
                    </mat-form-field>

                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

        <div class="row">

          <div class="col-12">

            <div class="form-group">

              <div class="form-row pt-4">

                <div class="col-12">

                  <!-- Form - Button -->
                  <div class="form-row justify-content-between">

                    <div class="text-right pr-1">

                      <button mat-flat-button type="submit">
                        <i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{ 'user.save' | translate }}
                      </button>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</form>
