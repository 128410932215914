<!-- Section Table -->
<div class="col-md-12 shadowbox pb-3">

  <div class="grid">

    <div class="col-12">

      <h5 class="heading my-3"><i class="fas fa-file-alt"></i>&nbsp;&nbsp;{{ 'contracts.title' | translate }}</h5>

      <div class="table-responsive">

        <table mat-table [dataSource]="contracts" class="table">
          <!-- Id -->
          <ng-container matColumnDef="contractId">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let contract">{{ asContract(contract)?.id }}</td>
          </ng-container>

          <!-- Worker Name -->
          <ng-container matColumnDef="workerName">
            <th mat-header-cell *matHeaderCellDef>{{ 'contracts.worker' | translate }}</th>
            <td mat-cell *matCellDef="let contract">
              {{asContract(contract)?.worker?.fullName}}
            </td>
          </ng-container>

          <!-- Start Date -->
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'contracts.startDate' | translate }}</th>
            <td mat-cell *matCellDef="let contract">
              {{asContract(contract)?.startDate | date: 'dd/MM/yyyy'}}
            </td>
          </ng-container>

          <!-- End Date -->
          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'contracts.endDate' | translate }}</th>
            <td mat-cell *matCellDef="let contract">
              {{asContract(contract)?.endDate | date: 'dd/MM/yyyy'}}
            </td>
          </ng-container>

          <!-- Contract Type -->
          <ng-container matColumnDef="contractType">
            <th mat-header-cell *matHeaderCellDef>{{ 'contracts.contractType' | translate }}</th>
            <td mat-cell *matCellDef="let contract">{{asContract(contract)?.contractType}}</td>
          </ng-container>

          <!-- Cost Center -->
          <ng-container matColumnDef="costCenter">
            <th mat-header-cell *matHeaderCellDef>{{ 'contracts.costCenter' | translate }}</th>
            <td mat-cell *matCellDef="let contract">{{asContract(contract)?.costCenter}}</td>
          </ng-container>

          <!-- Download -->
          <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef>{{ 'contracts.file' | translate }}</th>
            <td mat-cell *matCellDef="let contract">
              <button mat-flat-button type="button" color="accent" (click)="downloadDocument(contract)">
                <!-- <i class="fas fa-download"></i> -->
              <i class="fas fa-file-pdf"></i>&nbsp;&nbsp;{{ 'contracts.PDF' | translate }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let contract; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[5, 25, 75]" showFirstLastButtons></mat-paginator>

      </div>

    </div>

  </div>

</div>
